import * as t from 'io-ts'
import { GetStaticProps } from 'next'
import { validate } from '../api-utils'
import { manyOrNone } from '../api-utils/db'
import { HomePage } from '../components/ds'

type Props = {
  series: Series
}

export default function Index({ series }: Props) {
  return (
    <>
      <HomePage series={series} />
    </>
  )
}

const Serie = t.type({
  series_id: t.string,
  series_name: t.string,
  series_type: t.string,
  series_description: t.string,
  series_start: t.string, // ISO datetime string
})
type Serie = t.TypeOf<typeof Serie>
const Series = t.array(Serie)
export type Series = t.TypeOf<typeof Series>

export const getStaticProps: GetStaticProps = async () => {
  const query = `
    SELECT
      s.id as series_id,
      s.name as series_name,
      s."type" as series_type,
      s.description as series_description,
      to_json(s."start")#>>'{}' as series_start
    FROM series s
    JOIN series_state ss ON ss.series_id = s.id
    WHERE ss."state" = 'started'
    ORDER BY s.start ASC`

  const data = await manyOrNone<Serie>(query)
  const series = validate(Series, data)

  return {
    props: {
      series
    },
  }
}
